exports.components = {
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-accountfeatures-index-jsx": () => import("./../../../src/pages/accountfeatures/index.jsx" /* webpackChunkName: "component---src-pages-accountfeatures-index-jsx" */),
  "component---src-pages-buzzermode-index-jsx": () => import("./../../../src/pages/buzzermode/index.jsx" /* webpackChunkName: "component---src-pages-buzzermode-index-jsx" */),
  "component---src-pages-choice-index-jsx": () => import("./../../../src/pages/choice/index.jsx" /* webpackChunkName: "component---src-pages-choice-index-jsx" */),
  "component---src-pages-docs-autogen-index-jsx": () => import("./../../../src/pages/docs/autogen/index.jsx" /* webpackChunkName: "component---src-pages-docs-autogen-index-jsx" */),
  "component---src-pages-docs-questionbank-index-jsx": () => import("./../../../src/pages/docs/questionbank/index.jsx" /* webpackChunkName: "component---src-pages-docs-questionbank-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memory-index-jsx": () => import("./../../../src/pages/memory/index.jsx" /* webpackChunkName: "component---src-pages-memory-index-jsx" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/notFound.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-ourreviews-index-jsx": () => import("./../../../src/pages/ourreviews/index.jsx" /* webpackChunkName: "component---src-pages-ourreviews-index-jsx" */),
  "component---src-pages-plans-index-jsx": () => import("./../../../src/pages/plans/index.jsx" /* webpackChunkName: "component---src-pages-plans-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

